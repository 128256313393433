<template>
  <div>
    <v-divider inset class="pr-4 pl-4 mb-4"></v-divider>
    <v-form ref="interpolationForm" class="mt-4 pr-2 pl-2">
      <div class="d-flex">
        <v-text-field
          label="Mínimo"
          outlined
          type="number"
          dense
          class="managrx-input mr-2"
          v-model.number="yieldFloor"
          append-icon="mdi-barley"
          :rules="yieldFloorRules"
        ></v-text-field>
        <v-text-field
          label="Máximo"
          type="number"
          outlined
          dense
          class="managrx-input mr-2"
          append-icon="mdi-barley"
          v-model.number="yieldCeiling"
          :rules="yieldCeilingRules"
        ></v-text-field>

        <v-text-field
          label="Mínimo (0-100)%"
          type="number"
          outlined
          dense
          append-icon="mdi-percent-outline"
          class="managrx-input mr-2 ml-2"
          v-model.number="percentFloor"
          :rules="percentFloorRules"
        ></v-text-field>
        <v-text-field
          label="Máximo (0-100)%"
          type="number"
          outlined
          dense
          append-icon="mdi-percent-outline"
          class="managrx-input mr-2"
          v-model.number="percentCeiling"
          :rules="percentCeilingRules"
        ></v-text-field>

        <v-btn
          depressed
          color="primary white--text"
          class="mr-2"
          @click="addVariableShareCropInterpolation()"
          :disabled="!deleteAllButtonDisabled"
        >
          Interpolar
        </v-btn>

        <v-btn depressed color="info" class="mr-2" @click="reset()">
          <v-icon> mdi-restart</v-icon>
        </v-btn>
        <v-btn
          depressed
          color="warning"
          class="mr-2"
          @click="emitShowVariableShareCroppingSchedule"
          :disabled="deleteAllButtonDisabled"
        >
          <v-icon> mdi-eye</v-icon>
        </v-btn>
        <div class="d-flex justify-end">
          <v-btn
            color="red white--text"
            elevation="0"
            @click="emitDeleteAllVariableShareCroppingSchedules"
            :disabled="deleteAllButtonDisabled"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </div>
    </v-form>
  </div>
</template>
<script>
import { NEW_DRAFT_VARIABLE_SHARECROPPING_RULES } from "../../managrx/formsFieldsRules";
import { validateForm } from "../../managrx/utils";

let defaultObligatory = ["Campo obligatorio"];

function interpolate(minYield, maxYield, minPercent, maxPercent) {
  const yieldRange = maxYield - minYield;
  const percentRange = maxPercent - minPercent;

  const interpolatedData = [];

  for (let yieldValue = minYield; yieldValue <= maxYield; yieldValue++) {
    const percent =
      minPercent + ((yieldValue - minYield) / yieldRange) * percentRange;
    interpolatedData.push({ yield: yieldValue, percent: percent });
  }

  return interpolatedData;
}

export default {
  name: "AbstractNewVariableShareCropping",
  props: {
    activitySliceId: Number,
    deleteAllButtonDisabled: Boolean,
  },
  data() {
    return {
      yieldFloor: null,
      yieldCeiling: null,
      percentFloor: null,
      percentCeiling: null,
    };
  },
  computed: {
    yieldFloorRules() {
      if (this.yieldFloor && this.yieldFloor <= 0) {
        return defaultObligatory;
      }
      let fieldRules = NEW_DRAFT_VARIABLE_SHARECROPPING_RULES?.floor;
      let detail = validateForm(this.yieldFloor, fieldRules);
      return detail;
    },
    yieldCeilingRules() {
      if (this.yieldCeiling && this.yieldCeiling <= 0) {
        return defaultObligatory;
      }

      if (this.yieldCeiling < this.yieldFloor) {
        return ["Rinde piso es mayor que el techo"];
      }

      let fieldRules = NEW_DRAFT_VARIABLE_SHARECROPPING_RULES?.ceiling;
      let detail = validateForm(this.yieldFloor, fieldRules);
      return detail;
    },
    percentFloorRules() {
      if (this.percentFloor <= 0) {
        return defaultObligatory;
      }
      if (this.percentFloor > this.percentCeiling) {
        return ["Piso es mayor que techo"];
      }
      return [];
    },
    percentCeilingRules() {
      if (this.percentFloor <= 0) {
        return defaultObligatory;
      }
      if (this.percentFloor > this.percentCeiling) {
        return ["Piso es mayor que techo"];
      }
      return [];
    },
  },
  methods: {
    addVariableShareCropInterpolation() {
      if (!this.$refs.interpolationForm.validate()) {
        return;
      }

      if (this.yieldFloor != 0) {
        const interpolatedData = interpolate(
          this.yieldFloor,
          this.yieldCeiling,
          this.percentFloor,
          this.percentCeiling
        );

        let processedInterpolationData = interpolatedData
          .map((d) => {
            return {
              floor: d.yield,
              ceiling: d.yield + 1,
              percent: d.percent / 100,
            };
          })
          .slice(0, interpolatedData.length - 1);

        this.$emit(
          "variableSharecroppingInterpolationRowSave",
          processedInterpolationData
        );
        //this.reset();
      }
    },
    reset() {
      this.$refs.interpolationForm.resetValidation();
      this.floor = null;
      this.ceiling = null;
      this.percentAsInt = null;
      this.yieldFloor = null;
      this.yieldCeiling = null;
      this.percentFloor = null;
      this.percentCeiling = null;
    },
    emitShowVariableShareCroppingSchedule() {
      this.$emit("showVariableShareCroppingSchedule");
    },
    emitDeleteAllVariableShareCroppingSchedules() {
      this.$emit("deleteAllVariableShareCroppingSchedules");
    },
  },
};
</script>
