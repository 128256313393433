<template>
  <div>
    <v-form class="ma-5 mb-7">
      <v-text-field
        v-model.number="localDynamicPrice"
        label="Precio"
        type="number"
        :hint="isPriceModify"
        persistent-hint
        :readonly="!editMode"
        :filled="!editMode"
        class="managrx-input mb-1"
        outlined
        dense
        :class="{ 'modified-input': activityPrice != localDynamicPrice }"
        required
      ></v-text-field>
      <v-text-field
        :hint="isDynamicYieldModify"
        persistent-hint
        v-model.number="localDynamicYield"
        label="Rinde dinámico"
        type="number"
        :readonly="!editMode"
        :filled="!editMode"
        class="managrx-input mb-2"
        outlined
        dense
        :class="{ 'modified-input': dynamicYield != localDynamicYield }"
        required
      ></v-text-field>

      <v-btn
        :depressed="true"
        color="warning white--text"
        class="me-2"
        :disabled="editMode"
        @click="editMode = !editMode"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        depressed
        color="info"
        class="me-2"
        :disabled="!notOriginValues"
        @click="resetValues"
      >
        <v-icon> mdi-restart</v-icon>
      </v-btn>

      <v-btn
        :depressed="true"
        color="primary white--text"
        :disabled="!editMode"
        @click="editMode = !editMode"
      >
        Ok
      </v-btn>
    </v-form>

    <table class="px-3">
      <tr>
        <th class="cross">
          <div class="d-flex" color="grey lighten-2">
            <span class="ps-2 pt-2 mr-auto align-self-end"> Rinde </span>

            <span class="pb-2 pe-2 align-self-start"> Precio </span>
          </div>
        </th>
        <th class="axisX" v-for="value in axisX" :key="`${value}id1`">
          {{ value }}
        </th>
      </tr>
      <tr class="rows" v-for="valueY in axisY" :key="`${valueY}id2`">
        <td class="axisY">{{ valueY }}</td>
        <td
          class="content outlined"
          v-for="valueX in axisX"
          :key="`${valueX}id3`"
        >
          {{ setCellContentValue(valueY, valueX) }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { parseToLocaleString, roundToX } from "../../managrx/utils";
export default {
  name: "AbstractCartesianProductTable",
  props: {
    activityPrice: Number,
    dynamicYield: Number,
    saleTax: Number,
    saleComission: Number,
    saleHandOver: Number,
    totalOtherTonCost: Number,
    freightPrice: Number,
    technicalDirection: Number,
    percentHc: Number,
    maxHc: Number,
    minHc: Number,
    nonAutofinanciableCosts: Number,
    fixedSharecroppingPercent: Number,
    varaibleSharecroppingArray: Array,
  },
  data() {
    return {
      localDynamicPrice: 0,
      localDynamicYield: 0,
      editMode: false,
      cartesianProductAxis: [0.75, 0.9, 1, 1.25],
    };
  },
  beforeMount() {
    this.resetValues();
  },
  methods: {
    setCellContentValue(dynamicYieldPercent, activityPricePercent) {
      // dynamicYield of the cell
      let cellDynamicYield = this.localDynamicYield * dynamicYieldPercent;

      // activityPrice of the cell
      let cellActivityPrice = this.localDynamicPrice * activityPricePercent;

      // ownTons coefficients that should be applied if dynamicYield would be different in x percent
      // (1 - %AV - %AF)
      let ownTonsCoefficients = this.getOwnTonsCoefficients(cellDynamicYield);

      // harvest cost by the cell activityPrice and dynamicYield
      let harvestCost = this.getHarvestCost(
        cellDynamicYield,
        cellActivityPrice
      );
      // profitsByHa in function of dynamicYield and activityPrice
      let profitsByHa =
        // GrossProfit
        (cellDynamicYield / 10) * ownTonsCoefficients * cellActivityPrice -
        // SaleExpenses

        (cellDynamicYield / 10) *
          ownTonsCoefficients *
          (cellActivityPrice * (this.saleTax + this.saleComission) +
            this.saleHandOver) -
        // OTC

        (cellDynamicYield / 10) * ownTonsCoefficients * this.totalOtherTonCost -
        // FreightCosts

        (cellDynamicYield / 10) * ownTonsCoefficients * this.freightPrice -
        // TechnicalDirection

        (cellDynamicYield / 10) *
          (cellActivityPrice * (1 - (this.saleTax + this.saleComission)) +
            this.saleHandOver) *
          this.technicalDirection -
        // HarvestCost
        harvestCost -
        // NonAutofinanciables Costs
        this.nonAutofinanciableCosts;

      return parseToLocaleString(roundToX(profitsByHa, 0));
    },
    getOwnTonsCoefficients(dynamicYield) {
      return (
        1 -
        this.fixedSharecroppingPercent -
        this.getApplicableVaraibleSharecropping(dynamicYield)
      );
    },
    getApplicableVaraibleSharecropping(dynamicYield) {
      let variableSharecroppingsValues = 0;
      for (const vsc of this.varaibleSharecroppingArray) {
        if (dynamicYield > vsc.floor && dynamicYield < vsc.ceiling) {
          variableSharecroppingsValues += vsc.percent;
        }
      }
      return variableSharecroppingsValues;
    },
    getHarvestCost(dynamicYield, activityPrice) {
      let hc = (1 / 10) * activityPrice * dynamicYield * this.percentHc;
      if (hc > this.maxHc) {
        return this.maxHc;
      } else if (hc < this.minHc) {
        return this.minHc;
      } else {
        return hc;
      }
    },

    resetValues() {
      this.localDynamicPrice = this.activityPrice;
      this.localDynamicYield = this.dynamicYield;
    },
  },

  computed: {
    axisX() {
      return [...this.cartesianProductAxis];
    },
    axisY() {
      return [...this.cartesianProductAxis];
    },
    notOriginValues() {
      if (
        this.localDynamicPrice != this.activityPrice ||
        this.localDynamicYield != this.dynamicYield
      ) {
        return true;
      }
      return false;
    },
    isDynamicYieldModify() {
      return this.dynamicYield != this.localDynamicYield
        ? "*El valor fue modificado"
        : "";
    },
    isPriceModify() {
      return this.activityPrice != this.localDynamicPrice
        ? "*El valor fue modificado"
        : "";
    },
  },
  components: {},
};
</script>

<style scoped>
table {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

tr {
  height: 60px;
}
.axisX {
  color: white;
  font-weight: 300;
  background: #aaaa;
  width: 200px;
  margin: 10px;
}
.axisY {
  color: white;
  font-weight: 300;
  background: #aaaa;
  width: 200px;
  margin: 10px;
  text-align: center;
}
.content {
  text-align: center;
  border-color: #aaaa;
  border-style: solid;
  border-width: thin;
}
.rows {
  border: 4mm ridge black !important;
}
.cross span {
  position: relative;
  z-index: 2;
}

.cross {
  position: relative;
  height: 50px;
}

.cross:before,
.cross:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.cross:before {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 1px),
    #aaaa 50%,
    transparent calc(50% + 1px)
  );
}
</style>
