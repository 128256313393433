<template>
  <div class="pr-2 pl-2">
    <div class="mb-4 d-flex justify-space-between">
      <div>
        <h3 class="grey--text text--darken-2 display font-weight-medium">
          {{ interpolationParameters.yieldFloor }}
        </h3>
        <h4 class="grey--text font-weight-medium">Rinde Mín</h4>
      </div>
      <div>
        <h3 class="grey--text text--darken-2 font-weight-medium">
          {{ interpolationParameters.yieldCeil }}
        </h3>
        <h4 class="grey--text font-weight-medium">Rinde Máx</h4>
      </div>
      <div>
        <h3 class="grey--text text--darken-2 font-weight-medium">
          {{ interpolationParameters.percentFloor }}
        </h3>
        <h4 class="grey--text font-weight-medium">% Mín</h4>
      </div>
      <div>
        <h3 class="grey--text text--darken-2 font-weight-medium">
          {{ interpolationParameters.percentCeil }}
        </h3>
        <h4 class="grey--text font-weight-medium">% Máx</h4>
      </div>
      <div>
        <h3 class="grey--text text--darken-2 font-weight-medium">
          {{ interpolationParameters.currentlyAppliedPercent }}
        </h3>
        <h4 class="grey--text font-weight-medium">% Aplicado</h4>
      </div>

      <br />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 400px;
}
.managrx-input {
  font-weight: 400 !important;
}
.rounded-start {
  border-radius: 5px 0px 0px 5px;
}

.rounded-end {
  border-radius: 0px 5px 5px 0px;
}
</style>
<script>
import { roundToX } from "../../managrx/utils";
export default {
  name: "AbstractVariableShareCroppingSummary",
  data() {
    return {};
  },
  props: {
    variableShareCroppingRows: Array,
    activitySliceYield: Number,
  },
  mounted() {},
  computed: {
    sortedVariableSharecroppingRows() {
      return [...this.variableShareCroppingRows].sort(
        (a, b) => a.floor - b.floor
      );
    },
    interpolationParameters() {
      //
      let n = this.variableShareCroppingRows.length - 1;
      //
      return {
        yieldFloor: this.sortedVariableSharecroppingRows[0]?.floor || "0",
        yieldCeil: this.sortedVariableSharecroppingRows[n]?.ceiling || "∞",
        percentFloor:
          this.sortedVariableSharecroppingRows[0]?.percent * 100 || 0,
        percentCeil:
          this.sortedVariableSharecroppingRows[n]?.percent * 100 || 0,
        currentlyAppliedPercent:
          100 *
          roundToX(
            this.sortedVariableSharecroppingRows.find(
              (d) =>
                this.activitySliceYield >= d.floor &&
                this.activitySliceYield < d.ceiling
            )?.percent || 0,
            4
          ),
      };
    },
  },
  methods: {},
};
</script>
