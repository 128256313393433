<template>
  <AbstractVariableShareCroppingSummary
    :variableShareCroppingRows="variableShareCroppingRows"
    :activitySliceYield="activitySliceForwardYield"
  />
</template>

<script>
import AbstractVariableShareCroppingSummary from "../Abstract/AbstractVariableShareCroppingSummary.vue";
export default {
  name: "VariableShareCroppingSummary",
  components: {
    AbstractVariableShareCroppingSummary,
  },
  props: {
    variableShareCroppingRows: Array,
    activitySliceForwardYield: Number,
  },
  methods: {},
};
</script>
