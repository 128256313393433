<template>
  <div class="pr-2 pl-2">
    <div class="d-flex" v-if="!editMode">
      <v-text-field
        :value="assignedHectares"
        label="Hectáreas Asignadas"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>

      <v-text-field
        :value="dynamicYield"
        label="Rinde Dinámico"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>

      <v-text-field
        :value="roundToX(fixedSharecroppingPercent * dynamicYield, 2)"
        label="Cultivo Compartido Qq"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>

      <v-text-field
        :value="fixedSharecroppingPercentAsInt"
        label="Cultivo Compartido (0-100)%"
        class="managrx-input mr-2"
        outlined
        append-icon="mdi-percent-outline"
        dense
        readonly
        filled
      ></v-text-field>

      <v-btn
        :depressed="true"
        v-if="!deleteMode"
        color="warning white--text"
        class="mr-2"
        @click="editClick"
      >
        <v-icon> mdi-pencil</v-icon>
      </v-btn>
    </div>

    <div class="d-flex" v-if="editMode">
      <v-text-field
        v-model="newRow.assignedHectares"
        label="Hectáreas Asignadas"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>

      <v-text-field
        v-model="newRow.dynamicYield"
        label="Rinde Dinámico"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>

      <v-text-field
        v-model.number="newRow.fixedSharecroppingPercent"
        type="number"
        label="Cultivo Compartido (0-100)%"
        class="managrx-input mr-2"
        append-icon="mdi-percent-outline"
        outlined
        dense
      ></v-text-field>

      <v-btn
        :depressed="true"
        v-if="!deleteMode"
        color="primary white--text"
        class="mr-2"
        @click="saveClick"
      >
        <v-icon> mdi-floppy</v-icon>
      </v-btn>
      <v-btn
        :depressed="true"
        v-if="!deleteMode"
        color="info white--text"
        class="mr-2"
        @click="backClick"
      >
        Atrás
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 400px;
}
.managrx-input {
  font-weight: 400 !important;
}
</style>

<script>
import { roundToX } from "../../managrx/utils";

export default {
  name: "AbstractSimpleShareCropping",
  props: {
    isDraft: Boolean,
    assignedHectares: Number,
    dynamicYield: Number,
    fixedSharecroppingPercent: Number,
  },
  data() {
    return {
      select: null,
      editMode: false,
      deleteMode: false,
      newRow: {
        fixedSharecroppingPercent: null,
      },
    };
  },

  computed: {
    fixedSharecroppingPercentAsInt() {
      return this.fixedSharecroppingPercent * 100;
    },
    fixedSharecroppingPercentAsDecimal() {
      return this.newRow.fixedSharecroppingPercent / 100;
    },
  },
  methods: {
    deleteClick() {
      this.deleteMode = true;
    },
    async saveClick() {
      let payload = {
        fixedSharecroppingPercent: this.fixedSharecroppingPercentAsDecimal,
      };
      this.editMode = false;
      this.$emit("rowSave", payload);
    },
    editClick() {
      this.newRow = {
        assignedHectares: this.assignedHectares,
        fixedSharecroppingPercent: this.fixedSharecroppingPercentAsInt,
        dynamicYield: this.dynamicYield,
      };
      this.editMode = true;
    },
    backClick() {
      this.editMode = false;
      this.deleteMode = false;
    },
    roundToX,
  },
};
</script>
