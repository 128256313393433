<template>
  <v-form ref="form" class="pr-2 pl-2">
    <div class="d-flex">
      <v-text-field
        :label="detailLabel"
        outlined
        dense
        :rules="technicalDirectionRules"
        append-icon="mdi-percent-outline"
        class="managrx-input mr-2"
        type="number"
        v-model.number="calculatedValue"
      />

      <v-text-field
        :label="secondLabel"
        outlined
        dense
        class="managrx-input mr-2"
        type="date"
        readonly
        filled
        :value="slicedIncomeDate"
      />
      <v-btn
        depressed
        color="primary white--text"
        class="mr-2"
        @click="saveClick"
      >
        <v-icon> mdi-floppy</v-icon>
      </v-btn>
      <v-btn depressed color="info" class="mr-2" @click="resetForm">
        <v-icon> mdi-restart </v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 400px;
}
.managrx-input {
  font-weight: 400 !important;
}

.rounded-start {
  border-radius: 5px 0px 0px 5px;
}

.rounded-end {
  border-radius: 0px 5px 5px 0px;
}
</style>

<script>
import { DRAFT_TECHNICAL_DIRECTIONS_AND_EXPENSES_RULES } from "../../managrx/formsFieldsRules";
import { validateForm } from "../../managrx/utils";
export default {
  name: "AbstractIncomeBasedCostInput",
  props: {
    incomeDate: {
      type: String,
      validator(incomeDate) {
        const pattern =
          /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/g;
        const found = incomeDate.match(pattern);
        return found.length === 1;
      },
    },
    isPct: Boolean,
    isDraft: Boolean,
    componentRelevantValue: Number,
    activitySliceId: Number,
    firstLabel: String,
    secondLabel: String,
  },
  mounted() {
      this.calculatedValue = this.isPct ? this.componentRelevantValue * 100 :  this.componentRelevantValue;
    
  },
  methods: {
    resetForm() {
      this.$refs.form.resetValidation();
      this.calculatedValue = this.isPct ? this.componentRelevantValue * 100 : this.componentRelevantValue;
    },
    saveClick() {
      if (this.$refs.form.validate()) {
        let payload = {
          id: this.activitySliceId,
          newValue: this.isPct ? this.calculatedValue / 100 : this.calculatedValue,
        };
        this.$emit("saveClick", payload);
      }
    },
  },
  computed: {
    detailLabel(){
      return this.isPct ? this.firstLabel  + ' (0-100)%' : this.firstLabel 
    },
    slicedIncomeDate() {
      return this.incomeDate?.slice(0, 10);
    },
    technicalDirectionRules() {
      if (!this.calculatedValue && this.calculatedValue != 0) {
        return ["Campo obligatorio"];
      }
      let fieldRules = DRAFT_TECHNICAL_DIRECTIONS_AND_EXPENSES_RULES.uniqueRule;
      let detail = validateForm(this.calculatedValue, fieldRules);
      return detail;
    },
  },
  data() {
    return {
      calculatedValue: null,
    };
  },
};
</script>
