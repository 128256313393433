<template>
  <v-form ref="form" class="pr-2 pl-2">
    <div class="d-flex">
      <v-select
        ref="costItemSelect"
        v-model="select"
        @change="computeSubtotal"
        open-on-clear
        :items="costItems"
        return-object
        item-text="name"
        item-value="id"
        :rules="required"
        label="Elemento de costo"
        dense
        outlined
        class="managrx-input mr-2"
      ></v-select>

      <v-text-field
        class="managrx-input mr-2"
        @change="computeSubtotal"
        outlined
        dense
        :rules="priceTonRules"
        label="$/tn"
        v-model.number="newRow.priceTn"
        type="number"
      ></v-text-field>
      <v-text-field
        class="managrx-input mr-2"
        @change="computeSubtotal"
        outlined
        dense
        append-icon="mdi-percent-outline"
        label="(0-100)% App"
        :rules="percentAppRules"
        v-model.number="newRow.percentApplicationAsInt"
        type="number"
      ></v-text-field>
      <v-text-field
        class="managrx-input mr-2"
        @change="computeSubtotal"
        outlined
        dense
        filled
        label="Subtotal"
        v-model.number="itemSubtotal"
        type="text"
        readonly
      ></v-text-field>

      <v-btn
        depressed
        color="primary white--text"
        class="mr-2"
        @click="saveClick"
      >
        <v-icon> mdi-floppy</v-icon>
      </v-btn>
      <v-btn depressed color="info" class="mr-2" @click="resetNewRow">
        <v-icon> mdi-restart</v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 400px;
}
.managrx-input {
  font-weight: 400 !important;
}
</style>

<script>
import { NEW_DRAFT_TON_BASED_COST_RULES } from "../../managrx/formsFieldsRules";
import { newTonBasedCostValidator } from "../../managrx/propsValidators";
import {
  parseToLocaleString,
  roundToX,
  validateForm,
} from "../../managrx/utils";

export default {
  name: "AbstractNewTonBasedCost",
  props: {
    assignedHectares: Number,
    activitySliceId: Number,
    dynamicYield: Number,
    fixedShareCroppingValue: Number,
    variableShareCroppingValue: Number,
    otherTonCostItems: {
      type: Array,
      validator: newTonBasedCostValidator,
    },
  },
  computed: {
    percentAppAsDecimal() {
      if (this.newRow.percentApplicationAsInt) {
        return this.newRow.percentApplicationAsInt / 100;
      }
      return null;
    },
    required() {
      return [(v) => !!v || "Campo obligatorio"];
    },
    priceTonRules() {
      if (!this.newRow.priceTn && this.newRow.priceTn != 0) {
        return ["Campo obligatorio"];
      }
      let fieldRules = NEW_DRAFT_TON_BASED_COST_RULES?.priceTon;
      let detail = validateForm(this.newRow.priceTn, fieldRules);
      return detail;
    },
    percentAppRules() {
      if (
        !this.newRow.percentApplicationAsInt &&
        this.newRow.percentApplicationAsInt != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = NEW_DRAFT_TON_BASED_COST_RULES?.percentApp;
      let detail = validateForm(
        this.newRow.percentApplicationAsInt,
        fieldRules
      );
      return detail;
    },

    producedTons() {
      return roundToX(this.assignedHectares * this.dynamicYield * (1 / 10), 2);
    },
    ownTons() {
      return roundToX(
        this.producedTons *
          (1 - this.variableShareCroppingValue - this.fixedShareCroppingValue),
        2
      );
    },
  },
  methods: {
    computeSubtotal() {
      this.itemSubtotal = parseToLocaleString(
        roundToX(
          this.ownTons * this.newRow.priceTn * this.percentAppAsDecimal,
          2
        )
      );
    },

    async saveClick() {
      if (this.$refs.form.validate()) {
        let payload = {
          activitySliceId: this.activitySliceId,
          otherTonCostId: this.select?.id,
          priceTn: this.newRow.priceTn,
          percentApplication: this.percentAppAsDecimal,
        };

        this.$emit("rowSave", payload);
        this.itemSubtotal = 0;
        this.resetNewRow();
      }
    },
    resetNewRow() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
  async mounted() {
    this.costItems = [...this.otherTonCostItems];
  },
  data() {
    return {
      newRow: {
        percentApplicationAsInt: null,
        priceTn: null,
      },
      itemSubtotal: 0,
      select: "",
      costItems: [],
    };
  },
};
</script>
