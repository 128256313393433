let assignedSliceCostRowKeys = [
  "assignedDirectSliceCostItemId",
  "activitySliceId",
  "isActual",
  "tax",
  "itemInsuranceCost",
  "listedDirectSliceCostItemId",
  "directSliceCostItemId",
  "unitMeasure",
  "unitsPerHectare",
  "percentApplication",
  "dateUsed",
  "datePaid",
  "price",
  "directSliceCostItemName",
  "costItemFamilyName",
  "costItemNaturalOrder",
  "periodType",
  "costInputPeriodId",
  "assignedHectares",
  "paymentPolicyName",
  "paymentPolicyId",
  "policyJson",
  "itemCost",
  "itemCostInterest",
  "rate",
  "interestRateId",
  "interestRateName",
  "subTotal",
  "family",
  "units",
  "selectedRate",
];

let basicSingleActivitySliceKeys = [
  "assignedHectares",
  "dynamicYield",
  "sowDate",
  "harvestDate",
  "incomeDate",
  "minHarvestCost",
  "maxHarvestCost",
  "harvestPercentCost",
  "rentPercentApplication",
  "grainQualityFactor",
  "saleTax",
  "fixedSharecroppingPercent",
  "saleComission",
  "saleHandOver",
  "technicalDirection",
];

let sliceProductsListKeys = ["items", "keys", "options"];
let financialPoliciesKeys = ["id", "name", "policyJson"];
let interestRatesKeys = ["id", "name", "rate"];
let sliceInputCostPeriodsKeys = ["id", "name"];
let newTonBasedCostKeys = ["id", "price", "campaignId", "name", "tax"];

function financialPoliciesValidator(array) {
  return array.every((fp) => validateObjectByKeys(fp, financialPoliciesKeys));
}
function sliceInputCostPeriodsValidator(array) {
  return array.every((period) =>
    validateObjectByKeys(period, sliceInputCostPeriodsKeys)
  );
}
function interestRatesValidator(array) {
  return array.every((ir) => validateObjectByKeys(ir, interestRatesKeys));
}
function validateObjectByKeys(object, arr) {
  return Object.keys(object).every((o) => arr.includes(o));
}

function assignedDirectSliceCostRowValidator(object) {
  return validateObjectByKeys(object, assignedSliceCostRowKeys);
}
function assignedDirectDraftSliceCostRowValidator(object) {
  object;
  return true; //validateObjectByKeys(object, assignedSliceCostRowKeys);
}

function sliceProductsListValidator(object) {
  return (
    validateObjectByKeys(object, sliceProductsListKeys) &&
    Array.isArray(object.options)
  );
}

function newTonBasedCostValidator(array) {
  array.every((obj) => !validateObjectByKeys(obj, newTonBasedCostKeys));
  return true;
}

function singleActivitySliceValidator(object) {
  return basicSingleActivitySliceKeys.every((field) =>
    Object.keys(object).includes(field)
  );
}

export {
  assignedDirectSliceCostRowValidator,
  sliceProductsListValidator,
  financialPoliciesValidator,
  interestRatesValidator,
  sliceInputCostPeriodsValidator,
  newTonBasedCostValidator,
  singleActivitySliceValidator,
  assignedDirectDraftSliceCostRowValidator,
};
