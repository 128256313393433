<template>
  <div class="pr-2 pl-2">
    <div class="d-flex" v-if="!editMode">
      <div class="mr-2 mt-1" v-if="!isDraft">
        <v-tooltip v-if="isActual" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="" v-bind="attrs" v-on="on"
              >mdi-alpha-r-circle</v-icon
            >
          </template>
          <span>Realizado</span>
        </v-tooltip>
        <v-tooltip v-if="isProyected" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="" v-bind="attrs" v-on="on"
              >mdi-alpha-p-circle-outline</v-icon
            >
          </template>
          <span>Proyectado</span>
        </v-tooltip>
      </div>
      <v-text-field
        value="Cosecha"
        label="Familia"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>
      <v-text-field
        :value="forwardPrice"
        label="Precio en adelanto"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>
      <v-text-field
        :value="assignedHectares"
        label="Hectáreas Asignadas"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>
      <v-text-field
        :value="dynamicYield"
        label="Rinde Dinámico"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>
      <v-text-field
        :value="minHarvestCost"
        label="Cosecha Min"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>
      <v-text-field
        :value="maxHarvestCost"
        label="Cosecha Max"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>

      <v-text-field
        :value="harvestPercentCostAsInt"
        label="Porcentaje de Cosecha  (0-100) %"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>

      <v-text-field
        :value="harvestCostSubtotal"
        label="Costo Cosecha"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>

      <v-btn
        :depressed="true"
        v-if="!editMode"
        color="warning white--text"
        class="mr-2"
        @click="editClick"
      >
        <v-icon> mdi-pencil</v-icon>
      </v-btn>
    </div>
    <div class="d-flex" v-if="editMode">
      <div class="mr-2 mt-1" v-if="!isDraft">
        <v-tooltip v-if="isActual" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="" v-bind="attrs" v-on="on"
              >mdi-alpha-r-circle</v-icon
            >
          </template>
          <span>Realizado</span>
        </v-tooltip>
        <v-tooltip v-if="isProyected" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="" v-bind="attrs" v-on="on"
              >mdi-alpha-p-circle-outline</v-icon
            >
          </template>
          <span>Proyectado</span>
        </v-tooltip>
      </div>
      <v-text-field
        value="Cosecha"
        label="Familia"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>
      <v-text-field
        :value="forwardPrice"
        label="Precio en adelanto"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>
      <v-text-field
        :value="assignedHectares"
        label="Hectáreas Asignadas"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>
      <v-text-field
        :value="dynamicYield"
        label="Rinde Dinámico"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>
      <v-text-field
        v-model.number="newRow.minHarvestCost"
        type="number"
        label="Cosecha Min"
        class="managrx-input mr-2"
        outlined
        dense
      ></v-text-field>

      <v-text-field
        v-model.number="newRow.maxHarvestCost"
        type="number"
        label="Cosecha Max"
        class="managrx-input mr-2"
        outlined
        dense
      ></v-text-field>

      <v-text-field
        v-model.number="newRow.harvestPercentCost"
        type="number"
        label="Porcentaje de Cosecha"
        class="managrx-input mr-2"
        outlined
        dense
      ></v-text-field>

      <v-text-field
        :value="newHarvestCostSubtotal"
        label="Costo Cosecha"
        class="managrx-input mr-2"
        outlined
        dense
        readonly
        filled
      ></v-text-field>
      <v-select
        v-if="!isDraft"
        outlined
        class="managrx-input mr-2"
        dense
        label="Estado"
        open-on-clear
        persistent-hint
        :hint="
          newRow.isActualHarvestCost !== isActualHarvestCost
            ? 'El estado fue modificado'
            : ''
        "
        :items="actualsItems"
        v-model="newRow.isActualHarvestCost"
        item-value="value"
        item-text="name"
      >
      </v-select>

      <v-btn
        :depressed="true"
        v-if="editMode"
        color="primary white--text"
        class="mr-2"
        @click="saveClick"
      >
        <v-icon> mdi-floppy</v-icon>
      </v-btn>
      <v-btn
        :depressed="true"
        v-if="editMode"
        color="warning white--text"
        class="mr-2"
        @click="backClick"
      >
        Atrás
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.background-muted {
  background-color: #fafafa;
  opacity: 0.3;
}
.v-text-field {
  width: 300px;
}
.managrx-input {
  font-weight: 400 !important;
}
</style>

<script>
import { HARVEST_FREIGHT_ACTUAL_ITEMS } from "../../managrx/constants";
import { roundToX } from "../../managrx/utils";

function calculateHarvestCostSubtotal(percentHCost, maxHCost, minHCost) {
  if (percentHCost > maxHCost) {
    return roundToX(maxHCost, 2);
  }
  if (percentHCost < minHCost) {
    return roundToX(minHCost, 2);
  }
  return roundToX(percentHCost, 2);
}

export default {
  name: "AbstractHarvestCostInput",
  props: {
    isActualHarvestCost: Boolean,
    isDraft: Boolean,
    assignedHectares: Number, // Hectares
    minHarvestCost: Number, // Dollars per hectare
    maxHarvestCost: Number, // Dollars per hectare
    harvestPercentCost: Number, // Percent of gross income
    dynamicYield: Number, // Quintals per hectare
    forwardPrice: Number, // Dollars
  },

  methods: {
    deleteClick() {
      this.deleteMode = true;
    },

    async saveClick() {
      let payload = {
        minHarvestCost: this.newRow.minHarvestCost,
        maxHarvestCost: this.newRow.maxHarvestCost,
        harvestPercentCost: this.harvestPercentCostAsDecimal,
      };

      if (
        !this.isDraft &&
        this.newRow.isActualHarvestCost !== this.isActualHarvestCost
      ) {
        payload.isActualHarvestCost = this.newRow.isActualHarvestCost;
      }
      this.$emit("saveClick", payload);
      this.editMode = false;
    },
    editClick() {
      let newRow = {
        minHarvestCost: this.minHarvestCost,
        maxHarvestCost: this.maxHarvestCost,
        harvestPercentCost: this.harvestPercentCostAsInt,
      };
      if (!this.isDraft) {
        newRow.isActualHarvestCost = this.isActualHarvestCost;
      }
      this.newRow = newRow;
      this.editMode = true;
    },
    backClick() {
      this.editMode = false;
      this.deleteMode = false;
    },
  },
  computed: {
    isActual() {
      // true
      return this.isActualHarvestCost === true;
    },
    isProyected() {
      // null it means proyected
      return this.isActualHarvestCost === null;
    },
    harvestPercentCostAsInt() {
      return this.harvestPercentCost * 100;
    },
    harvestPercentCostAsDecimal() {
      return this.newRow.harvestPercentCost / 100;
    },
    harvestCostSubtotal() {
      let percentHCost =
        this.dynamicYield *
        (1 / 10) *
        this.harvestPercentCost *
        this.forwardPrice;
      let minHCost = this.minHarvestCost;
      let maxHCost = this.maxHarvestCost;

      return calculateHarvestCostSubtotal(percentHCost, maxHCost, minHCost);
    },
    newHarvestCostSubtotal() {
      let percentHCost =
        this.dynamicYield *
        (1 / 10) *
        this.harvestPercentCost *
        this.forwardPrice;
      let minHCost = this.newRow.minHarvestCost;
      let maxHCost = this.newRow.maxHarvestCost;

      return calculateHarvestCostSubtotal(percentHCost, maxHCost, minHCost);
    },
  },

  data() {
    return {
      actualsItems: HARVEST_FREIGHT_ACTUAL_ITEMS,
      editMode: false,
      newRow: {
        isActualHarvestCost: null,
        minHarvestCost: 0,
        maxHarvestCost: 0,
        harvestPercentCost: 0,
      },
    };
  },
};
</script>
