var useFindables = {
  methods: {
    getPaymentPolicyNameById(paymentPolicyId) {
      let candidate = this.financialPolicies.find(
        (policy) => policy.id === paymentPolicyId
      )?.name;
      return candidate ? candidate : "";
    },
    getPeriodIdByName(periodName) {
      let candidate = this?.sliceInputCostPeriods?.find(
        (p) => p.name === periodName
      )?.id;
      return candidate ? candidate : "";
    },
  },
};

export default useFindables;
