// CONST for reapetead RULES
const FIELDTYPE_N_CANTBLANK = {
  cantBeBlank: true,
  fieldType: "number",
};
const MIN_0_FIELDTYPE_N_CANTBLANK = {
  minValue: 0,
  cantBeBlank: true,
  fieldType: "number",
};
const MIN_1_FIELDTYPE_N_CANTBLANK = {
  minValue: 1,
  cantBeBlank: true,
  fieldType: "number",
};
const MAX_100_MIN_0_FIELDTYPE_N_CANTBLANK = {
  minValue: 0,
  maxValue: 100,
  cantBeBlank: true,
  fieldType: "number",
};
const FIELDTYPE_S_CANTBLANK = {
  fieldType: "string",
  cantBeBlank: true,
};

// Globals RULES
const DRAFT_GLOBALS_RULES = {
  assignedHectares: MIN_0_FIELDTYPE_N_CANTBLANK,
  finalActivityValue: MIN_0_FIELDTYPE_N_CANTBLANK,
  sowDate: FIELDTYPE_S_CANTBLANK,
  activityPrice: MIN_0_FIELDTYPE_N_CANTBLANK,
  harvestDate: FIELDTYPE_S_CANTBLANK,
  incomeDate: FIELDTYPE_S_CANTBLANK,
  dynamicYield: MIN_1_FIELDTYPE_N_CANTBLANK,
  forwardYield: MIN_1_FIELDTYPE_N_CANTBLANK,
  minHarvestCost: MIN_0_FIELDTYPE_N_CANTBLANK,
  maxHarvestCost: MIN_0_FIELDTYPE_N_CANTBLANK,
  fixedSharecroppingPercent: MAX_100_MIN_0_FIELDTYPE_N_CANTBLANK,
  rentPercentApplication: MAX_100_MIN_0_FIELDTYPE_N_CANTBLANK,
  freightCostTn: MIN_0_FIELDTYPE_N_CANTBLANK,
  harvestPercentCost: MAX_100_MIN_0_FIELDTYPE_N_CANTBLANK,
  saleTax: MIN_0_FIELDTYPE_N_CANTBLANK,
  saleComission: MIN_0_FIELDTYPE_N_CANTBLANK,
  saleHandOver: MIN_0_FIELDTYPE_N_CANTBLANK,
  technicalDirection: MIN_0_FIELDTYPE_N_CANTBLANK,
  grainQualityFactor: MIN_0_FIELDTYPE_N_CANTBLANK,
};

const PROJECT_GLOBALS_RULES = {};

// Technical direction RULES

const DRAFT_TECHNICAL_DIRECTIONS_AND_EXPENSES_RULES = {
  uniqueRule: MIN_0_FIELDTYPE_N_CANTBLANK,
};

const PROJECT_TECHNICAL_DIRECTIONS_AND_EXPENSES_RULES = {};

// SandG RULES

const NEW_DRAFT_SANDG_FORM_RULES = {
  priceHa: FIELDTYPE_N_CANTBLANK,
  monthsConsideration: MIN_1_FIELDTYPE_N_CANTBLANK,
};

const NEW_PROJECT_SANDG_FORM_RULES = {
  product: FIELDTYPE_S_CANTBLANK,
  monthsConsideration: MIN_1_FIELDTYPE_N_CANTBLANK,
};

// New Slice Cost Input RULES

const NEW_DRAFT_SLICE_COST_INPUT_FORM_RULES = {
  price: FIELDTYPE_N_CANTBLANK,
  percentApp: MAX_100_MIN_0_FIELDTYPE_N_CANTBLANK,
  units: MIN_0_FIELDTYPE_N_CANTBLANK,
  datePaid: FIELDTYPE_S_CANTBLANK,
  dateUsed: FIELDTYPE_S_CANTBLANK,
};
const NEW_PROJECT_SLICE_COST_INPUT_FORM_RULES = {
  percentApp: MAX_100_MIN_0_FIELDTYPE_N_CANTBLANK,
  units: MIN_0_FIELDTYPE_N_CANTBLANK,
  datePaid: FIELDTYPE_S_CANTBLANK,
  dateUsed: FIELDTYPE_S_CANTBLANK,
};

// New Variable Sharecropping RULES

const NEW_DRAFT_VARIABLE_SHARECROPPING_RULES = {
  floor: MIN_0_FIELDTYPE_N_CANTBLANK,
  ceiling: MIN_0_FIELDTYPE_N_CANTBLANK,
  percentApp: MAX_100_MIN_0_FIELDTYPE_N_CANTBLANK,
};

const NEW_PROJECT_VARIABLE_SHARECROPPING_RULES = {
  floor: MIN_0_FIELDTYPE_N_CANTBLANK,
  ceiling: MIN_0_FIELDTYPE_N_CANTBLANK,
  percentApp: MAX_100_MIN_0_FIELDTYPE_N_CANTBLANK,
};

// New Ton Based Cost RULES

const NEW_DRAFT_TON_BASED_COST_RULES = {
  priceTon: MIN_1_FIELDTYPE_N_CANTBLANK,
  percentApp: MAX_100_MIN_0_FIELDTYPE_N_CANTBLANK,
};
const NEW_PROJECT_TON_BASED_COST_RULES = {
  product: FIELDTYPE_S_CANTBLANK,
  priceTon: MIN_1_FIELDTYPE_N_CANTBLANK,
  percentApp: MAX_100_MIN_0_FIELDTYPE_N_CANTBLANK,
};

// New Campaign Engagement

const NEW_CAMPAIGN_ENGAGEMENT = {
  variableParticipation: MAX_100_MIN_0_FIELDTYPE_N_CANTBLANK,
};
export {
  NEW_CAMPAIGN_ENGAGEMENT,
  NEW_DRAFT_SANDG_FORM_RULES,
  NEW_DRAFT_TON_BASED_COST_RULES,
  NEW_DRAFT_VARIABLE_SHARECROPPING_RULES,
  NEW_DRAFT_SLICE_COST_INPUT_FORM_RULES,
  DRAFT_TECHNICAL_DIRECTIONS_AND_EXPENSES_RULES,
  DRAFT_GLOBALS_RULES,
  NEW_PROJECT_SANDG_FORM_RULES,
  NEW_PROJECT_SLICE_COST_INPUT_FORM_RULES,
  NEW_PROJECT_TON_BASED_COST_RULES,
  NEW_PROJECT_VARIABLE_SHARECROPPING_RULES,
  PROJECT_TECHNICAL_DIRECTIONS_AND_EXPENSES_RULES,
  PROJECT_GLOBALS_RULES,
};
