<template>
  <div class="pr-2 pl-2">
    <div class="d-flex" v-if="editMode">
      <v-text-field
        v-model.number="rowEdits.floor"
        label="Mínimo"
        type="number"
        outlined
        dense
        class="managrx-input mr-2"
      ></v-text-field>
      <v-text-field
        v-model.number="rowEdits.ceiling"
        label="Máximo"
        outlined
        dense
        type="number"
        class="managrx-input mr-2"
      ></v-text-field>
      <v-text-field
        @change="updateQQ"
        type="number"
        v-model.number="rowEdits.percent"
        label="Porcentaje"
        outlined
        dense
        class="managrx-input mr-2"
      ></v-text-field>
      <v-text-field
        v-model="qq"
        label="Quintals"
        readonly
        outlined
        filled
        dense
        class="managrx-input mr-2"
      ></v-text-field>

      <v-btn
        depressed
        color="primary white--text"
        class="mr-2"
        @click="saveClick"
      >
        <v-icon> mdi-floppy</v-icon>
      </v-btn>
      <v-btn depressed color="info white--text" class="mr-2" @click="backClick">
        Atrás
      </v-btn>
    </div>

    <div class="d-flex" v-if="!editMode">
      <!-- <div class="mr-2 mt-1" v-if="!isDraft">
        <v-tooltip v-if="isActual" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="success" v-bind="attrs" v-on="on"
              >mdi-tag-text-outline</v-icon
            >
          </template>
          <span>Realizado</span>
        </v-tooltip>
        <v-tooltip v-if="isProyected" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="warning" v-bind="attrs" v-on="on"
              >mdi-tag-hidden</v-icon
            >
          </template>
          <span>Proyectado</span>
        </v-tooltip>
        <v-tooltip v-if="isNotActual" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="error" v-bind="attrs" v-on="on">mdi-tag-off</v-icon>
          </template>
          <span>No realizado</span>
        </v-tooltip>
      </div> -->
      <v-text-field
        :value="floor"
        label="Mínimo"
        type="number"
        outlined
        dense
        class="managrx-input mr-2"
        readonly
        filled
      ></v-text-field>
      <v-text-field
        :value="ceiling"
        label="Máximo"
        type="number"
        outlined
        dense
        class="managrx-input mr-2"
        readonly
        filled
      ></v-text-field>
      <v-text-field
        :value="percentAsInt"
        label="(0-100)%"
        append-icon="mdi-percent-outline"
        type="number"
        outlined
        dense
        class="managrx-input mr-2"
        readonly
        filled
      ></v-text-field>
      <v-text-field
        :value="qq"
        label="Qq/ha"
        outlined
        dense
        class="managrx-input mr-2"
        readonly
        filled
      ></v-text-field>
      <!-- <div v-if="!deleteMode && !isDraft" class="d-flex flex-row">
        <v-tooltip v-if="isProyected" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="rounded-start"
              :depressed="true"
              color=""
              v-bind="attrs"
              v-on="on"
              @click="updateActualStatus(true)"
            >
              <v-icon color="success">mdi-tag-check-outline</v-icon>
            </v-btn>
          </template>
          <span>Realizado</span>
        </v-tooltip>
        <v-tooltip v-if="isActual || isNotActual" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="rounded-start"
              :depressed="true"
              color=""
              v-bind="attrs"
              v-on="on"
              @click="updateActualStatus(null)"
            >
              <v-icon color="warning">mdi-tag-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>Proyectado</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="rounded-0"
              :depressed="true"
              color=""
              :disabled="isNotActual"
              v-bind="attrs"
              v-on="on"
              @click="updateActualStatus(false)"
            >
              <v-icon color="error">mdi-tag-minus-outline</v-icon>
            </v-btn>
          </template>
          <span>No realizado</span>
        </v-tooltip>
      </div> -->
      <v-btn
        :depressed="true"
        v-if="!deleteMode"
        color="warning white--text"
        class="mr-2"
        :class="{ 'rounded-end': !isDraft }"
        @click="editClick"
      >
        <v-icon> mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        v-if="deleteMode"
        :depressed="true"
        color="error"
        class="mr-2 white--text lighten-1"
        @click="confirmDeleteClick"
      >
        Confirmar borrado
      </v-btn>
      <v-btn
        :depressed="true"
        v-if="deleteMode"
        color="warning white--text"
        class="mr-2"
        @click="backClick"
      >
        Atrás
      </v-btn>
      <v-btn
        depressed
        color="error  white--text"
        class="mr-2"
        @click="deleteClick"
        v-if="!deleteMode"
      >
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 400px;
}
.managrx-input {
  font-weight: 400 !important;
}
.rounded-start {
  border-radius: 5px 0px 0px 5px;
}

.rounded-end {
  border-radius: 0px 5px 5px 0px;
}
</style>
<script>
import { roundToX } from "../../managrx/utils";
export default {
  name: "AbstractVariableShareCropping",
  data() {
    return {
      editMode: false,
      deleteMode: false,
      rowEdits: {},
      qq: "",
    };
  },
  props: {
    variableShareCroppingId: Number,
    isDraft: Boolean,
    dynamicYield: Number,
    floor: Number,
    ceiling: Number,
    percent: Number,
    actualStatus: Boolean,
  },
  mounted() {
    this.qq = roundToX(this.dynamicYield * this.percent, 2);
  },
  computed: {
    isActual() {
      // true
      return this.actualStatus === true;
    },
    isProyected() {
      // null it means proyected
      return this.actualStatus === null;
    },
    isNotActual() {
      // Not proyected nor actual
      return this.actualStatus === false;
    },
    percentAsInt() {
      return this.percent * 100;
    },
    rowPercentAsDecimal() {
      return this.rowEdits.percent / 100;
    },
  },
  methods: {
    updateActualStatus(status) {
      this.$emit("updateActualStatus", status);
    },
    updateQQ() {
      this.qq = roundToX(this.dynamicYield * this.rowPercentAsDecimal, 2);
    },
    deleteClick() {
      this.deleteMode = true;
    },
    async saveClick() {
      let payload = this.rowEdits;
      payload.percent = this.rowPercentAsDecimal;
      this.$emit("saveClick", payload);
      this.editMode = false;
    },
    confirmDeleteClick() {
      let payload = this.variableShareCroppingId;
      this.$emit("confirmDeleteRow", payload);
      this.deleteMode = false;
    },
    editClick() {
      this.rowEdits = {
        id: this.variableShareCroppingId,
        floor: this.floor,
        ceiling: this.ceiling,
        percent: this.percentAsInt,
      };
      this.editMode = true;
    },
    backClick() {
      this.editMode = false;
      this.deleteMode = false;
    },
    resetrowEdits() {
      this.rowEdits = {};
    },
  },
};
</script>
