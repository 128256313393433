import {
  roundToX,
  parseToLocaleString,
  sortArrayOfObjectsByAlphabet,
} from "../managrx/utils";

var useSharedSliceCosts = {
  computed: {
    selectableDraftCostItems() {
      if (this?.directSliceCostItems) {
        let directSliceCostItems = this.directSliceCostItems?.filter((obj) =>
          this.families.some((f) => f === obj.costItemFamilyName)
        );
        if (Object.keys(directSliceCostItems).length > 1) {
          // This procedure will sort the array base on the key "name" on an ascending way
          sortArrayOfObjectsByAlphabet(directSliceCostItems, "name");
        }

        return directSliceCostItems;
      }
      return null;
    },

    selectableCostItems() {
      if (this?.sliceProductsList) {
        let sliceProductsList = this.sliceProductsList?.options?.filter(
          (obj) =>
            (obj.regionId === this.region || obj.regionId === null) &&
            (obj.activityId === this.activity || obj.activityId === null) &&
            obj.campaignId === this.campaign &&
            this.families.some((f) => f === obj.costItemFamilyName)
        );
        if (Object.keys(sliceProductsList).length > 1) {
          // This procedure will sort the array base on the key "value" on an ascending way
          sortArrayOfObjectsByAlphabet(sliceProductsList, "value");
        }
        return sliceProductsList;
      }
      return null;
    },
  },
  methods: {
    computeSubtotal() {
      let st =
        this.newRow.interestRateId === null
          ? roundToX(
              this.newRow.units *
                this.newRow.price *
                this.assignedHectares *
                this.percentAppAsDecimal,
              2
            )
          : roundToX(
              this.newRow.units *
                this.newRow.price *
                this.assignedHectares *
                this.percentAppAsDecimal *
                (1 + this.newRow.rate),
              2
            );

      this.newRow.subTotal = parseToLocaleString(st);
    },
    changePaymentPolicySelect(item) {
      this.newRow.paymentPolicy = item;
    },
    changePeriodSelect(item) {
      this.newRow.costInputPeriodId = item.id;
      this.computeSubtotal();
    },
    changeCostItemSelect(item) {
      this.newRow.id = item.directSliceCostItemId;
      this.newRow.listedDirectSliceCostItemId =
        item.listedDirectSliceCostItemId;
      this.newRow.costInputPeriodId = item.costInputPeriodId;
      this.newRow.family = item.costItemFamilyName;

      this.newRow.unitMeasure = item.unitMeasure;
      this.newRow.price = item.price;

      this.computeSubtotal();
    },
    changeInterestSelect(item) {
      console.log(`changeInterestSelect: ${item.rate}`);
      this.newRow.interestRateId = item.id;
      this.newRow.rate = item.rate;
      this.computeSubtotal();
      console.log(`newRow.subTotal: ${this.newRow.subTotal}`);
    },
    changePaymentOption(item) {
      this.paymentOption = item;
    },
    makePretty(v) {
      return (
        v?.toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        }) ??
        (0.0).toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        })
      );
    },
    getDirectSliceCostItemNameById(listedDirectSliceCostItemId) {
      let candidate = this?.sliceProductsList?.options.find(
        (sliceProduct) =>
          sliceProduct.listedDirectSliceCostItemId ===
          listedDirectSliceCostItemId
      );
      return candidate ? candidate.value : "";
    },
    getDirectSliceFamilyCostItemNameById(listedDirectSliceCostItemId) {
      let candidate = this?.sliceProductsList?.options.find(
        (sliceProduct) =>
          sliceProduct.listedDirectSliceCostItemId ===
          listedDirectSliceCostItemId
      );

      return candidate ? candidate.costItemFamilyName : "";
    },
    getDirectSliceCostItemUnitMeasureById(listedDirectSliceCostItemId) {
      let candidate = this?.sliceProductsList?.options.find(
        (sliceProduct) =>
          sliceProduct.listedDirectSliceCostItemId ===
          listedDirectSliceCostItemId
      );
      return candidate ? candidate.unitMeasure : "";
    },
  },
};

export default useSharedSliceCosts;
