<template>
  <div class="">
    <div class="pr-4 pl-4 mb-8 d-flex justify-space-between">
      <div>
        <h3 class="grey--text text--darken-2 display font-weight-medium">
          ${{ unitPrice }}
        </h3>
        <h4 class="grey--text font-weight-medium">Costo USD/ha</h4>
      </div>
      <div>
        <h3 class="grey--text text--darken-2 font-weight-medium">
          ${{ periodCost }}
        </h3>
        <h4 class="grey--text font-weight-medium">USD/mes</h4>
      </div>
      <div>
        <h3 class="grey--text text--darken-2 font-weight-medium">
          {{ periodsConsidered }}
        </h3>
        <h4 class="grey--text font-weight-medium">Periodos</h4>
      </div>
      <div>
        <h3 class="grey--text text--darken-2 font-weight-medium">
          ${{ totalCost }}
        </h3>
        <h4 class="grey--text font-weight-medium">USD (total)</h4>
      </div>

      <br />
    </div>
  </div>
</template>

<script>
import { roundToX } from "../../managrx/utils";
export default {
  name: "SandGSliceSummary",
  components: {},
  props: {
    assignedSandGRows: Array,
  },
  methods: {},
  computed: {
    periodsConsidered() {
      return this.assignedSandGRows?.length || 0;
    },
    unitPrice() {
      if (this.firstRow) {
        return this.firstRow?.unitPrice
          ? this.firstRow?.unitPrice
          : this.firstRow?.price;
      } else {
        return 0;
      }
    },
    periodCost() {
      return this.firstRow
        ? roundToX(this.firstRow?.subTotal, 2).toLocaleString()
        : 0;
    },
    totalCost() {
      return this.firstRow
        ? roundToX(
            this.assignedSandGRows
              .map((el) => el?.subTotal)
              .reduce((a, b) => a + b, 0),
            0
          ).toLocaleString()
        : 0;
    },
    firstRow() {
      return this.assignedSandGRows?.[0];
    },
  },
};
</script>
